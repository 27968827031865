import request from '@/utils/request'

// 查询路段管理列表
export function listSection (query) {
  return request({
    url: '/iot/section/list',
    method: 'get',
    params: query
  })
}

// 查询路段管理详细
export function getSection (id) {
  return request({
    url: '/iot/section/' + id,
    method: 'get'
  })
}

// 新增路段管理
export function addSection (data) {
  return request({
    url: '/iot/section',
    method: 'post',
    data: data
  })
}

// 修改路段管理
export function updateSection (data) {
  return request({
    url: '/iot/section',
    method: 'put',
    data: data
  })
}

// 删除路段管理
export function delSection (id) {
  return request({
    url: '/iot/section/' + id,
    method: 'delete'
  })
}

// 导出路段管理
export function exportSection (query) {
  return request({
    url: '/iot/section/export',
    method: 'get',
    params: query
  })
}
