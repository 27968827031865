<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="路段名称" prop="roadName" >
        <a-input v-model="form.roadName" placeholder="请输入路段名称" />
      </a-form-model-item>
      <a-form-model-item label="路段宽度" prop="roadWidth" >
        <a-input v-model="form.roadWidth" placeholder="请输入路段宽度" />
      </a-form-model-item>
      <a-form-model-item label="所属标段" prop="bidSection" >
        <a-input v-model="form.bidSection" placeholder="请输入所属标段" />
      </a-form-model-item>
      <a-form-model-item label="作业单位" prop="operationUnit">
        <a-tree-select
          v-model="form.operationUnit"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="管理单位" prop="manageUnit">
        <a-tree-select
          v-model="form.manageUnit"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="所属区域" prop="region" >
        <a-input v-model="form.region" placeholder="请输入所属区域" />
      </a-form-model-item>
      <a-form-model-item label="排序号" prop="sort" >
        <a-input v-model="form.sort" placeholder="请输入排序号" />
      </a-form-model-item>
      <a-form-model-item label="负责人" prop="responsible" >
        <a-input v-model="form.responsible" placeholder="请输入负责人" />
      </a-form-model-item>
      <a-form-model-item label="绑定的图元" prop="primitives">
        <a-select
          v-model="form.primitives"
          placeholder="请选择"
        >
          <a-select-option v-for="(d, index) in primitiveMOptions" :key="index" :value="d.id">
            {{ d.typeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="绑定规则" prop="taskRule" >
        <a-input v-model="form.taskRule" placeholder="请输入绑定规则" />
      </a-form-model-item>
      <a-form-model-item label="是否删除" prop="status" >
        <a-select placeholder="请选择是否删除" v-model="form.status">
          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSection, addSection, updateSection } from '@/api/iot/section'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data () {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 图元选项
      primitiveMOptions: [],
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        roadName: null,
        roadWidth: null,
        bidSection: null,
        operationUnit: null,
        manageUnit: null,
        region: null,
        sort: null,
        responsible: null,
        primitives: null,
        taskRule: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        roadName: [
          { required: true, message: '路段名称不能为空', trigger: 'blur' }
        ],
        operationUnit: [
          { required: true, message: '作业单位不能为空', trigger: 'blur' }
        ],
        manageUnit: [
          { required: true, message: '管理单位不能为空', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '所属区域不能为空', trigger: 'blur' }
        ],
        primitives: [
          { required: true, message: '绑定的图元不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        roadName: null,
        roadWidth: null,
        bidSection: null,
        operationUnit: null,
        manageUnit: null,
        region: null,
        sort: null,
        responsible: null,
        primitives: null,
        taskRule: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      getSection(0).then(response => {
        this.primitiveMOptions = response.primitiveMs
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSection(id).then(response => {
        this.form = response.data
        this.primitiveMOptions = response.primitiveMs
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSection(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addSection(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
