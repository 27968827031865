var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "路段名称", prop: "roadName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入路段名称" },
                model: {
                  value: _vm.form.roadName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roadName", $$v)
                  },
                  expression: "form.roadName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "路段宽度", prop: "roadWidth" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入路段宽度" },
                model: {
                  value: _vm.form.roadWidth,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "roadWidth", $$v)
                  },
                  expression: "form.roadWidth",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属标段", prop: "bidSection" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入所属标段" },
                model: {
                  value: _vm.form.bidSection,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bidSection", $$v)
                  },
                  expression: "form.bidSection",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "作业单位", prop: "operationUnit" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.operationUnit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "operationUnit", $$v)
                  },
                  expression: "form.operationUnit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "管理单位", prop: "manageUnit" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.manageUnit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "manageUnit", $$v)
                  },
                  expression: "form.manageUnit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属区域", prop: "region" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入所属区域" },
                model: {
                  value: _vm.form.region,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "region", $$v)
                  },
                  expression: "form.region",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "排序号", prop: "sort" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入排序号" },
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "负责人", prop: "responsible" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入负责人" },
                model: {
                  value: _vm.form.responsible,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "responsible", $$v)
                  },
                  expression: "form.responsible",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "绑定的图元", prop: "primitives" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.primitives,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "primitives", $$v)
                    },
                    expression: "form.primitives",
                  },
                },
                _vm._l(_vm.primitiveMOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.id } },
                    [_vm._v(" " + _vm._s(d.typeName) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "绑定规则", prop: "taskRule" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入绑定规则" },
                model: {
                  value: _vm.form.taskRule,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskRule", $$v)
                  },
                  expression: "form.taskRule",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否删除", prop: "status" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择是否删除" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: Number(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }